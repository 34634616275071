export default [{
    header: 'Propiedades',
    icon: 'TagIcon',
    children: [
        {
            title: 'Lista',
            route: 'propers-list',
            icon: 'ListIcon',
            action: 'read',
		    resource: 'propers'
        },
        {
            title: 'Agregar',
            route: 'propers-create',
            icon: 'PlusSquareIcon',
            action: 'create',
		    resource: 'propers'
        }
    ]
}];